<template>
  <div>
    <!-- Table Container Card -->
    <module-list-add-new
      :key="activeModule.fullname"
      :is-add-new-module-sidebar-active.sync="isAddNewModuleSidebarActive"
      :module="activeModule"
      @refetch-data="refetchData"
    />
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end" />
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refModuleListTable"
        class="position-relative"
        :items="fetchModules"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t('noRecords')"
      >
        <template #cell(expired)="data">
          <small class="align-middle ml-50 text-dark">
            <feather-icon v-if="!data.item.expired" icon="CheckIcon" />
            <feather-icon
              v-if="data.item.expired"
              size="20"
              icon="XIcon"
              color="red"
            />
          </small>
        </template>
        <template #cell(detail)="data">
          <b-link @click="selectModule(data)">
            <feather-icon icon="FileTextIcon" />
            <small class="align-middle ml-50 text-dark">
              {{ $t("edit") }}
            </small>
          </b-link>
        </template>
        <!-- Column: Module -->
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BTable, BLink } from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import useModuleList from "../useModuleList";
import ModuleListAddNew from "./ModuleListAdd.vue";

export default {
  components: {
    ModuleListAddNew,
    BCard,
    BTable,
    BLink,
    BRow,
    BCol,
  },

  setup() {
    const isAddNewModuleSidebarActive = ref(false);
    const activeModule = ref({});
    const { fetchModules, tableColumns, refetchData, refModuleListTable } =
      useModuleList();
    const selectModule = (data) => {
      activeModule.value = data.item;
      isAddNewModuleSidebarActive.value = true;
    };
    return {
      // Sidebar
      isAddNewModuleSidebarActive,
      selectModule,
      fetchModules,
      activeModule,
      tableColumns,
      refModuleListTable,
      refetchData,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
