import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";
import VueI18n from "@/libs/i18n";
// Notification
import { useToast } from "vue-toastification/composition";

export default function useModuleList() {
  // Use toast
  const toast = useToast();

  const refModuleListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "fullname", label: VueI18n.t("name"), sortable: false },
    {
      key: "moduleName",
      label: VueI18n.t("module") + " " + VueI18n.t("name"),
      formatter: title,
      sortable: false,
    },
    // { label: VueI18n.t("price"), key: "pricepaid", sortable: false },
    { key: "purchasedate", label: VueI18n.t("purchaseDate") },
    { key: "expiredate", label: VueI18n.t("expireDate") },
    { key: "expired", label: VueI18n.t("expired") },
    { key: "detail", label: VueI18n.t("detail") },
  ];
  const perPage = ref(10);
  const totalModules = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const typeFilter = ref(null);
  const genderFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refModuleListTable.value
      ? refModuleListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalModules.value,
    };
  });

  const refetchData = () => {
    refModuleListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery, typeFilter, genderFilter, statusFilter],
    () => {
      refetchData();
    }
  );

  const fetchModules = async (ctx, callback) => {
    return await store.dispatch("modulesModule/fetchModules");
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchModules,
    tableColumns,
    refModuleListTable,
    refetchData,

    // Extra Filters
  };
}
