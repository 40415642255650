<template>
  <b-modal
    id="add-new-user-sidebar"
    centered
    :visible="isAddNewModuleSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    size="lg"
    backdrop
    no-header
    right
    :ok-title="$t('ok')"
    ok-only
    @ok="$refs.submitButtonRef.click()"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-module-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ $t("editModule") }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />
          <div v-if="blankModule.transferleft" class="mb-2 d-flex">
            <span
              >{{ $t("changeUserCount") }}: {{ blankModule.transferleft }}</span
            >
          </div>
          <b-form-group
            v-if="blankModule.transferleft > 0"
            :label="$t('user')"
            label-for="type"
          >
            <v-select
              v-model="blankModule.userid"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="userOption"
              class="w-100"
              :reduce="(val) => val.value"
            />
          </b-form-group>
          <!-- ad -->
          <b-form-group
            :label="$t('purchaseDate')"
            label-for="Satın Alma Tarihi"
          >
            <b-form-input
              id="Satın Alma Tarihi"
              v-model="blankModule.purchasedate"
              disabled
              autofocus
              trim
              placeholder=""
            />
          </b-form-group>
          <b-form-group
            :label="$t('expireDate')"
            label-for="Son Geçerlilik Tarihi"
          >
            <b-form-input
              id="Son Geçerlilik Tarihi"
              v-model="blankModule.expiredate"
              disabled
              autofocus
              trim
              placeholder=""
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-if="blankModule.transferleft"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("save") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BForm, BFormGroup, BFormInput, BButton } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    // Form Validation
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewModuleSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewModuleSidebarActive: {
      type: Boolean,
      required: true,
    },
    module: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      userOption: [],
      userType: "",
    };
  },
  async created() {
    var { data } = await store.dispatch("usersModule/fetchUsers", {
      id: getUserData().companyid,
      q: "",
      perPage: 200,
      page: 1,
      sortBy: "",
      sortDesc: "",
      type: "",
      gender: "",
      status: "",
    });
    data.map((x) => this.userOption.push({ label: x.fullname, value: x.id }));
  },

  setup(props, { emit }) {
    const blankModuleData = props.module;
    const blankModule = ref(JSON.parse(JSON.stringify(blankModuleData)));
    const resetblankModule = () => {
      blankModule.value = JSON.parse(JSON.stringify(blankModuleData));
    };
    const onSubmit = () => {
      store
        .dispatch("modulesModule/updateModuleUser", {
          userid: blankModule.value.userid,
          moduleid: blankModule.value.id,
        })
        .then(() => {
          emit("refetch-data");
          emit("update:is-add-new-module-sidebar-active", false);
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetblankModule);

    return {
      blankModule,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
