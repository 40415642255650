<template>
  <div><module-list action-tpye="dsada" /></div>
</template>

<script>
import ModuleList from "./ModuleList/ModuleList.vue";
export default {
  components: {
    ModuleList,
  },
  mounted() {},
};
</script>

<style></style>
