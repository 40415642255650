import { render, staticRenderFns } from "./ModuleListAdd.vue?vue&type=template&id=0498e732"
import script from "./ModuleListAdd.vue?vue&type=script&lang=js"
export * from "./ModuleListAdd.vue?vue&type=script&lang=js"
import style0 from "./ModuleListAdd.vue?vue&type=style&index=0&id=0498e732&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports